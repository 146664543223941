import React, {
  useContext,
} from 'react'
import { navigate } from "gatsby"
import { Page, Layout, LegacyCard, LegacyStack, ButtonGroup, Button, TextContainer, Badge, Text } from "@shopify/polaris";
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseContext from '../../../providers/firebase';
import styled from 'styled-components';
import OnboardingForNew from '../../../components/onBoardingForNew';
const CartOfferPreviewWrapper = styled.div`
    padding: 50px 0 10px 0;
    .preview-button {
        padding: 25px 0 15px 0;
        .Polaris-Button {
            width: 485px;
        }
    }
    .instructions {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 500px;
        margin: auto;
        .step {
            padding: 10px 0 5px 20px;
            .step-num {
                width: 26px;
                height: 26px;
                background: black; 
                -moz-border-radius: 13px; 
                -webkit-border-radius: 13px; 
                border-radius: 13px;
            }
        }
    }
    .action-buttons {
        padding: 15px 0 25px 0;
        display: flex;
        justify-content: center;
        .Polaris-ButtonGroup{
            .Polaris-ButtonGroup__Item:first-child {
                button {
                    width: 238px;
                }
            }
        }
    }
`
function CartOfferPopupSuccess(props) {
  const { location } = props
  const from = location && location.state && location.state.from
  const { firebase, shop } = useContext(FirebaseContext);
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc,
    {
      transform: (snap) => snap.shopData
    }
  );
  return (
    <Page backAction={{
                content: 'Offers',
                onAction: () => {
                    navigate("/app/offers")
                }}} 
                title="Create Popup Upsell">
     {from ? 
        <OnboardingForNew initStep={3} extensionName={"Cart upsell"} cartUpsellName={"Popup Upsell"}/>
        :
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <CartOfferPreviewWrapper>
                <div className='instructions'>
                  <TextContainer>
                    <Text variant="headingMd" as="h2">Congratulations! Your cart offer is now active</Text>
                    <p>
                      Let’s preview it to make sure everything works as expected
                    </p>
                  </TextContainer>

                  <p className='step'>
                    <Badge>1</Badge> Click <Button
                    size="slim"

                    onClick={
                      () => {
                        window.open(`https://${shopData.domain}?upsellplus-preview=true`, '_blank')
                      }}
                    variant="plain">here</Button> to go to your store
                  </p>
                  <p className='step'>
                    <Badge>2</Badge> Do you see the cart popup?
                  </p>
                </div>
                <div className='action-buttons'>
                  <ButtonGroup>
                    <div style={{color: '#008060'}}>
                      <Button   onClick={
                        () => {
                          navigate('/app/offers', {
                            replace: true,
                          })
                        }
                      }>Yes, it works perfectly!</Button>
                    </div>
                    <div style={{color: '#bf0711'}}>
                      <Button   onClick={() => {
                        if(window && window.$crisp){
                          window.$crisp.push(["do", "chat:open"]);
                        }
                      }}>Something is wrong, I need help</Button>
                    </div>
                  </ButtonGroup>
                </div>

                <LegacyStack distribution="trailing">
                  <ButtonGroup>
                    <Button

                      onClick={
                        () => {
                          navigate('/app/offers', {
                            replace: true,
                          })
                        }
                      }
                      variant="plain">
                      Back
                    </Button>
                    <Button variant="primary" onClick={
                      () => {
                        navigate('/app/offers', { replace: true,})
                      }
                    }>Go to offers overview</Button>
                  </ButtonGroup>
                </LegacyStack>
              </CartOfferPreviewWrapper>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    }
    </Page>
  );
}

export default CartOfferPopupSuccess;